import React, { useState, useEffect } from "react";

// components
import Table from "components/Table/TableUi";
import Modal from "components/Modal/Modal";
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import {instance as API} from "utils/API";
import PARAMS from "utils/PARAMS";
import { validateFields, showSnack } from 'utils/helperFunctions';
import Moment from 'moment';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import Loader from 'react-loader-spinner';
import Actions from "components/Actions/Actions";
import Button from "components/CustomButtons/Button";
import Dropzone from "components/DropZone/DropZone";
import readXlsxFile from 'read-excel-file';

// Core
import { makeStyles } from "@material-ui/core/styles";
import TextField from '@material-ui/core/TextField';
import Fab from '@material-ui/core/Fab';

// Icons
import AddIcon from '@material-ui/icons/Add';
import EditIcon from "@material-ui/icons/Edit";
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import SearchIcon from '@material-ui/icons/Search';
import PublishIcon from '@material-ui/icons/Publish';
import MapIcon from '@material-ui/icons/Map';
import Select2 from 'react-select';

export default function Parcelas() {
  const [parcelas, setParcelas] = useState([]);
  const [tableHead, setTableHead] = useState([
    {id: "oleicultor", numeric: false, disablePadding: true, label: 'Oleicultor'},
    {id: "municipio", numeric: false, disablePadding: true, label: 'Municipio'},
    {id: "superficie", numeric: false, disablePadding: true, label: 'Superficie'},
    {id: "acciones", numeric: false, disablePadding: true, label: 'Acciones'},
  ]);

  function createData(oleicultor, municipio, superficie, acciones) {
    return { oleicultor, municipio, superficie, acciones };
  }

  const [isLoad, setIsLoad] = React.useState(false);
  const [importing, setImporting] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [isProcessing, setIsProcessing] = React.useState(false);
  const [toSeach, setToSearch] = React.useState(null);
  const [parcela_id, setParcelaId] = React.useState('');
  const [parcela_municipio, setParcelaMunicipio] = React.useState('');
  const [parcela_poligono, setParcelaPoligono] = React.useState('');
  const [parcela_parcela, setParcelaParcela] = React.useState('');
  const [parcela_recinto, setParcelaRecinto] = React.useState('');
  const [parcela_superficie, setParcelaSuperficie] = React.useState('');
  const [imagen_map, setImagenMap] = React.useState([]);
  const [imagen_map_guardada, setImagenMapGuardada] = React.useState([]);

  const [parcela_oleicultor, setParcelaOleicultor] = React.useState('');
  const [listOleicultorOptions, setListOleicultorOptions] = React.useState([]);
  const handleParcelaOleicultor = selectedOption => {
    setParcelaOleicultor(selectedOption);
  };
  const [parcela_nivel, setParcelaNivel] = React.useState('');
  const [listNivelesOptions, setListNivelesOptions] = React.useState([]);
  const handleParcelaNivel = selectedOption => {
    setParcelaNivel(selectedOption);
  };

  const [openMap, setOpenMap] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [openEdit, setOpenEdit] = React.useState(false);
  const [openConfirm, setOpenConfirm] = React.useState(false);
  const handleClickOpen = () => {
    clearForm();
    setOpen(true);
  };
  const handleClickOpenEdit = () => {
    setOpenEdit(true);
  };
  const handleClickOpenConfirm = (id) => {
    setOpenConfirm(true);
    setParcelaId(id);
  };
  const handleClose = () => {
    setOpen(false);
    setOpenEdit(false);
    setOpenConfirm(false);
    setOpenMap(false);
  };

  const styles = {
    cardCategoryWhite: {
      "&,& a,& a:hover,& a:focus": {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0"
      },
      "& a,& a:hover,& a:focus": {
        color: "#FFFFFF"
      }
    },
    cardTitleWhite: {
      color: "#FFFFFF",
      marginTop: "0px",
      minHeight: "auto",
      fontWeight: "300",
      fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
      marginBottom: "3px",
      textDecoration: "none",
      "& small": {
        color: "#777",
        fontSize: "65%",
        fontWeight: "400",
        lineHeight: "1"
      }
    },
    root: {
      width: '100%',
    },
  };
  const useStyles = makeStyles(styles);
  Moment.locale('es');

  useEffect(() => {
    getParcelas();
    getMap();

    const inputParcelas = document.getElementById('inputFileParcelas');
    const inputAlertas = document.getElementById('inputFileAlertas');

    inputParcelas.addEventListener('change', () => {
      importParcelas(inputParcelas.files[0]);
      document.getElementById("inputFileParcelas").value = "";
    })
    inputAlertas.addEventListener('change', () => {
      importAlertas(inputAlertas.files[0]);
      document.getElementById("inputFileAlertas").value = "";
    })
  }, []);

  async function importParcelas(input) {
    setImporting(true);
    let data = new FormData();
    data.append('file', input);
    let settings = { headers: { 'content-type': 'multipart/form-data' } };
    await API.post("parcelas/import", data, settings)
    .then(res => {
      showSnack('success', 'Cargado correctamente');
      setImporting(false);
      setTimeout(function () {
        getParcelas();
      }, 1000);
    })
    .catch(err => {
      setImporting(false);
      if (err.response.data.error) {
        showSnack('warning', err.response.data.error);
      }else{
        showSnack('warning', 'Se ha producido un error');
      }
    });
  }

  async function importAlertas(input) {
    setImporting(true);
    let data = new FormData();
    data.append('file', input);
    let settings = { headers: { 'content-type': 'multipart/form-data' } };
    await API.post("alertas/import", data, settings)
    .then(res => {
      showSnack('success', 'Cargado correctamente');
      setImporting(false);
      setTimeout(function () {
        getParcelas();
      }, 1000);
    })
    .catch(err => {
      console.log(err);
      setImporting(false);
      if (err.response.data.error) {
        showSnack('warning', err.response.data.error);
      }else{
        showSnack('warning', 'Se ha producido un error');
      }
    });
  }

  async function getParcelas() {
    setIsLoad(false);
    await API.post("parcelas/index", {
      findBy: toSeach
    })
    .then(res => {
      if (res.data.listaOleicultores) {
        setListOleicultorOptions(res.data.listaOleicultores);
      }
      if (res.data.listaNiveles) {
        setListNivelesOptions(res.data.listaNiveles);
      }
      if (res.data.parcelas.length > 0) {
        const arrayParcelas = [];
        res.data.parcelas.forEach(parcela => {
          let oleicultor = parcela['oleicultor'];
          let nivel = parcela['nivel'];
          let aux = createData(
            parcela["oleicultor"]["nombre"] + ' (' + parcela["oleicultor"]["dni"] + ')',
            parcela["municipio"],
            parcela["superficie"],
            (<div className={classes.root}>
              <Actions
                edit={true}
                onEdit={() => loadEdit(
                  parcela['id'],
                  parcela["municipio"],
                  parcela["poligono"],
                  parcela["parcela"],
                  parcela["recinto"],
                  parcela["superficie"],
                  ((oleicultor) ? {'value': oleicultor['id'], 'label': oleicultor['nombre']} : []),
                  ((nivel) ? {'value': nivel['id'], 'label': nivel['tipo']} : [])
                )}
                del={true}
                onDelete={() => handleClickOpenConfirm(parcela['id'])}
              />
            </div>)
          );

          arrayParcelas.push(aux);
        });
        setParcelas(arrayParcelas);
        setIsLoad(true);
      }
    })
    .catch(err => {
      console.log(err);
      showSnack('warning', 'Se ha producido un error en la carga de parcelas,')
    });
  }

  function formParcelas(){
    return(<>
      <GridItem xs={6} sm={6} md={6} style={{ marginTop: "10px" }}>
        <TextField
          label="Municipio"
          defaultValue={parcela_municipio}
          onChange={event => {
            const { value } = event.target;
            setParcelaMunicipio(value);
          }}
          error={(error && parcela_municipio == '') ? true : false}
        />
      </GridItem>
      <GridItem xs={6} sm={6} md={6} style={{ marginTop: "10px" }}>
        <TextField
          label="Polígono"
          defaultValue={parcela_poligono}
          onChange={event => {
            const { value } = event.target;
            setParcelaPoligono(value);
          }}
          error={(error && parcela_poligono == '') ? true : false}
        />
      </GridItem>
      <GridItem xs={6} sm={6} md={6} style={{ marginTop: "10px" }}>
        <TextField
          label="Parcela"
          defaultValue={parcela_parcela}
          onChange={event => {
            const { value } = event.target;
            setParcelaParcela(value);
          }}
          error={(error && parcela_parcela == '') ? true : false}
        />
      </GridItem>
      <GridItem xs={6} sm={6} md={6} style={{ marginTop: "10px" }}>
        <TextField
          label="Recinto"
          defaultValue={parcela_recinto}
          onChange={event => {
            const { value } = event.target;
            setParcelaRecinto(value);
          }}
          error={(error && parcela_recinto == '') ? true : false}
        />
      </GridItem>
      <GridItem xs={6} sm={6} md={6} style={{ marginTop: "10px" }} >
        <TextField
          label="Superficie"
          defaultValue={parcela_superficie}
          onChange={event => {
            const { value } = event.target;
            setParcelaSuperficie(value);
          }}
          error={(error && parcela_superficie == '') ? true : false}
        />
      </GridItem>
      <GridItem xs={12} sm={12} md={12} style={{ marginTop: "20px" }} >
        <Select2
          value={parcela_oleicultor}
          onChange={handleParcelaOleicultor}
          options={listOleicultorOptions}
          className={(error && parcela_oleicultor.value == null) ? classes.error : ""}
          placeholder ="Selecciona oleicultor"
          menuPosition={'fixed'}
        />
      </GridItem>
      <GridItem xs={12} sm={12} md={12} style={{ marginTop: "20px" }} >
        <Select2
          value={parcela_nivel}
          onChange={handleParcelaNivel}
          options={listNivelesOptions}
          className={(error && parcela_nivel.value == null) ? classes.error : ""}
          placeholder ="Selecciona nivel de alerta"
          menuPosition={'fixed'}
        />
      </GridItem>
    </>);
  }

  function formMap(){
    return(<>
      <GridItem xs={12} sm={12} md={12} style={{ marginTop: "20px", marginBottom: "20px", width: "100%" }}>
        <Dropzone onLoadImage={(val) => handleImage(val)} initialFiles={imagen_map} multiple={false} maxFiles={1} insideMessage="Arrastre o suelte el archivo aquí, o haga clic para seleccionarlo"/>
      </GridItem>
      <GridItem xs={12} sm={12} md={12} style={{ marginTop: "20px", display: 'flex', flexDirection: 'column' }} >
        {(imagen_map_guardada) && <>
          <p style={{fontSize: 20, fontWeight: 'bold', color: '#2484FF'}}>Archivo Guardado</p>
          {(imagen_map_guardada) && <img src={imagen_map_guardada} width="100%"/>}
        </>}
      </GridItem>
    </>);
  }

  const handleImage = (img) => {
    var arrayImagenes = [];
    img.forEach((item, i) => {
      arrayImagenes[0] = item['file'];
    });
    setImagenMap(arrayImagenes);
  };

  const getMap = async() => {
    await API.post("parcelas/getMap")
    .then(async(res) => {
      if (res.data) {
        setImagenMapGuardada(res.data);
      }
    });
  };

  const saveMap = async() => {
    let data = new FormData();
    data.append('file', imagen_map[0]);
    console.log(imagen_map[0]);
    let settings = { headers: { 'content-type': 'multipart/form-data' } }
    await API.post("parcelas/uploadMap", data, settings)
    .then(async(res) => {
      if (res.data) {
        getMap();
      }
    });
  };

  async function createParcela(){
    if (!isProcessing) {
      var validate_fields = new Map([['nombre', parcela_municipio]]);
      var validate = validateFields(validate_fields);
      setError(false);
      if (validate.status) {
        setIsProcessing(true);
        await API.post("parcelas/store", {
          municipio: parcela_municipio,
          poligono: parcela_poligono,
          parcela: parcela_parcela,
          recinto: parcela_recinto,
          superficie: parcela_superficie,
          oleicultor_id: parcela_oleicultor['value'],
          alerta_id: parcela_nivel['value']
        })
        .then(res => {
          if (res.data) {
            handleClose();
            getParcelas();
            showSnack('success', 'Parcela creada correctamente');
          }
          setIsProcessing(false);
        })
        .catch(err => {
          if (err.response.data.error) {
            showSnack('warning', err.response.data.error);
          }else{
            showSnack('warning', 'Se ha producido un error');
          }
          setIsProcessing(false);
        });
      }else{
        showSnack('warning', validate.message);
        setError(true);
      }
    }
  };

  function clearForm(){
    setParcelaId('');
    setParcelaMunicipio('');
    setParcelaPoligono('');
    setParcelaParcela('');
    setParcelaRecinto('');
    setParcelaSuperficie('');
    setParcelaOleicultor([]);
    setParcelaNivel([]);
  }

  function loadEdit(id, municipio, poligono, parcela, recinto, superficie, oleicultor, nivel){
    setParcelaId(id);
    setParcelaMunicipio(municipio);
    setParcelaPoligono(poligono);
    setParcelaParcela(parcela);
    setParcelaRecinto(recinto);
    setParcelaSuperficie(superficie);
    setParcelaOleicultor(oleicultor);
    setParcelaNivel(nivel);
    handleClickOpenEdit();
  }

  async function editParcela(){
    if (!isProcessing) {
      var validate_fields = new Map([['municipio', parcela_municipio]]);
      var validate = validateFields(validate_fields);
      setError(false);
      if (validate.status) {
        setIsProcessing(true);
        await API.post(`parcelas/update/${parcela_id}`, {
          municipio: parcela_municipio,
          poligono: parcela_poligono,
          parcela: parcela_parcela,
          recinto: parcela_recinto,
          superficie: parcela_superficie,
          oleicultor_id: parcela_oleicultor['value'],
          alerta_id: parcela_nivel['value']
        })
        .then(res => {
          if (res.data) {
            handleClose();
            getParcelas();
            showSnack('success', 'Parcela editado correctamente');
          }
          setIsProcessing(false);
        })
        .catch(err => {
          if (err.response.data.error) {
            showSnack('warning', err.response.data.error);
          }else{
            showSnack('warning', 'Se ha producido un error');
          }
          setIsProcessing(false);
        });
      }else{
        showSnack('warning', validate.message);
        setError(true);
      }
    }
  }

  async function deleteParcela(){
    if (!isProcessing) {
      setIsProcessing(true);
      await API.post(`parcelas/destroy/${parcela_id}`)
      .then(res => {
        if (res.data) {
          handleClose();
          getParcelas();
          showSnack('success', 'Parcela eliminado correctamente');
          setIsProcessing(false);
        }
      })
      .catch(err => {
        console.log(err);
        setIsProcessing(false);
        showSnack('warning', 'Se ha producido un error, eliminando la parcela')
      });
    }
  };

  const classes = useStyles();

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="info">
            <h4 className={classes.cardTitleWhite}>Parcelas</h4>
            <p className={classes.cardCategoryWhite}>
              Gestión de parcelas
            </p>
          </CardHeader>
          <CardBody>
            <GridContainer direction="row"  alignItems="flex-end">
              <GridItem xs={3} sm={3} md={3} >
                <Fab style={{backgroundColor: PARAMS.firstColor, color: '#fff'}} variant="extended" onClick={() => handleClickOpen()}>
                  <AddIcon />
                  Crear parcela
                </Fab>
              </GridItem>
              <GridItem xs={3} sm={3} md={3} style={{flexDirection: 'row'}}>
                <TextField
                  label="Buscar"
                  defaultValue={toSeach}
                  onChange={event => {
                    const { value } = event.target;
                    setToSearch(value);
                  }}
                  onKeyPress={(event) => {
                    if (event.key === 'Enter') {
                      event.preventDefault();
                      const { value } = event.target;
                      setToSearch(value);
                      getParcelas();
                    }
                  }}
                />
                <Fab style={{backgroundColor: PARAMS.firstColor, color: '#fff'}} variant="extended" onClick={() => getParcelas()}>
                  <SearchIcon />
                </Fab>
              </GridItem>
              <GridItem xs={1} sm={1} md={1} />
              <GridItem xs={5} sm={5} md={5} direccion="row" style={{display: 'flex', justifyContent: 'flex-end'}}>
                <Button
                  variant="contained"
                  component="label"
                  style={{backgroundColor: PARAMS.firstColor, color: '#fff'}}
                  fullWidth={true}
                  onClick={() => setOpenMap(true)}
                >
                  <MapIcon style={{marginRight: 5}}/>
                  Cargar Mapa
                </Button>
              </GridItem>
            </GridContainer>
            <GridContainer direction="row" style={{paddingTop: 15}}>
              <GridItem xs={5} sm={5} md={5} direccion="row" style={{display: 'flex', justifyContent: 'flex-start'}}>
                <Button
                  variant="contained"
                  component="label"
                  style={{backgroundColor: PARAMS.firstColor, color: '#fff'}}
                  fullWidth={true}
                >
                  <PublishIcon style={{marginRight: 5}}/>
                  Importar Parcelas
                  <input type="file" id="inputFileParcelas" style={{ display: "none" }} />
                </Button>
              </GridItem>
              <GridItem xs={2} sm={2} md={2} direccion="row" style={{display: 'flex', justifyContent: 'center'}}>
                {importing && <Loader type={PARAMS.loaderType} color={PARAMS.firstColor} height={35} width={100} />}
              </GridItem>
              <GridItem xs={5} sm={5} md={5} direccion="row" style={{display: 'flex', justifyContent: 'flex-end'}}>
                <Button
                  variant="contained"
                  component="label"
                  style={{backgroundColor: PARAMS.firstColor, color: '#fff'}}
                  fullWidth={true}
                >
                  <PublishIcon style={{marginRight: 5}}/>
                  Importar Alertas
                  <input type="file" id="inputFileAlertas" style={{ display: "none" }} />
                </Button>
              </GridItem>
            </GridContainer>
            <div className={classes.root}>
              {(isLoad ?
                <>
                  <Table
                    tablePaginate={true}
                    tableHead={tableHead}
                    tableData={parcelas}
                  />
                </>
              :
                <><GridContainer style={{width: '100%', height: '300px'}} direction="row"  alignItems="center" justify="center">
                    <Loader type={PARAMS.loaderType} color={PARAMS.firstColor} timeout={5000} height={100} width={100} />
                  </GridContainer>
                </>
              )}
            </div>
          </CardBody>
        </Card>
      </GridItem>
      <div>
        <Modal
          open={open}
          onCancel={() => handleClose()}
          content={formParcelas()}
          onConfirm={() => createParcela()}
          title="Crear parcela"
        />
        <Modal
          open={openEdit}
          onCancel={() => handleClose()}
          content={formParcelas()}
          onConfirm={() => editParcela()}
          title="Editar parcela"
        />
        <Modal
          open={openConfirm}
          onCancel={() => handleClose()}
          onConfirm={() => deleteParcela()}
          confirmText="Confirmar"
          confirmIcon={<CheckCircleOutlineIcon style={{marginRight: '10px', color: '#fff'}} />} title="¿Seguro que deseas borrar el parcela?"  />
        <Modal
          open={openMap}
          onCancel={() => handleClose()}
          content={formMap()}
          onConfirm={() => saveMap()}
          title="Cargar Mapa"
          width="lg"
        />
      </div>
    </GridContainer>
  );
}
