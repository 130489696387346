import React from "react";
import axios from "axios";

export async function enviarNotificaciones(objNotificacion) {
  console.log(objNotificacion.usuariosDispositivos);

  var pusherNotification = axios.create({
    baseUrl: "https://exp.host/--/api/v2/push/send",
  });

  pusherNotification.defaults.headers.common["Content-Type"] =
    "application/json";

  objNotificacion.usuariosDispositivos.map((dispositivo) => {
    pusherNotification
      .post("https://exp.host/--/api/v2/push/send", {
        to: dispositivo.dispositivo,
        body: objNotificacion.notificacion.nombre,
        title: "Enviando Notificacion...",
      })
      .then(function (response) {
        return response;
      });
  });
}
