import React, { useState, useEffect, useRef } from "react";

// components
import Table from "components/Table/TableUi";
import Modal from "components/Modal/Modal";
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import { instance as API } from "utils/API";
import PARAMS from "utils/PARAMS";
import { validateFields, showSnack } from "utils/helperFunctions";
import Moment from "moment";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
import Actions from "components/Actions/Actions";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";

// Core
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Fab from "@material-ui/core/Fab";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { enviarNotificaciones } from "utils/globalFunctions";

// Icons
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import SearchIcon from "@material-ui/icons/Search";
import Select2 from "react-select";

export default function Avisos() {
  const [avisos, setAvisos] = useState([]);
  const [tableHead, setTableHead] = useState([
    { id: "nombre", date: false, disablePadding: true, label: "Nombre" },
    {
      id: "fecha",
      date: true,
      disablePadding: true,
      label: "Fecha de Creación",
    },
    { id: "acciones", date: false, disablePadding: true, label: "Acciones" },
  ]);

  function createData(nombre, fecha, acciones) {
    return { nombre, fecha, acciones };
  }

  const myRef = useRef(null);
  const [isLoad, setIsLoad] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [isProcessing, setIsProcessing] = React.useState(false);
  const [toSeach, setToSearch] = React.useState(null);
  const [aviso_id, setAvisoId] = React.useState("");
  const [aviso_nombre, setAvisoNombre] = React.useState("");
  const [aviso_descripcion, setAvisoDescripcion] = React.useState(
    EditorState.createEmpty()
  );
  const [aviso_isGeneral, setAvisoIsGeneral] = React.useState("");

  const [aviso_categoria, setAvisoCategoria] = React.useState("");
  const [listCategoriaOptions, setListCategoriaOptions] = React.useState([]);
  const handleAvisoCategoria = (selectedOption) => {
    setAvisoCategoria(selectedOption);
  };

  const [aviso_zona, setAvisoZona] = React.useState("");
  const [listZonaOptions, setListZonaOptions] = React.useState([]);
  const handleAvisoZona = (selectedOption) => {
    setAvisoZona(selectedOption);
    setAvisoOleicultor([]);
    setAvisoEntidad([]);
  };

  const [aviso_oleicultor, setAvisoOleicultor] = React.useState([]);
  const [listOleicultorOptions, setListOleicultorOptions] = React.useState([]);
  const handleAvisoOleicultor = (selectedOption) => {
    setAvisoOleicultor(selectedOption);
  };
  const [aviso_entidad, setAvisoEntidad] = React.useState([]);
  const [listEntidadesOptions, setListEntidadesOptions] = React.useState([]);
  const handleAvisoEntidad = (selectedOption) => {
    var aux = aviso_oleicultor;
    var add = true;
    if (!aux) {
      aux = [];
    }
    listOleicultorOptions.forEach((oleicultor, i) => {
      add = true;
      aux.forEach((selected, i) => {
        if (selected.value == oleicultor.value) {
          add = false;
        }
      });
      if (oleicultor.entidad == selectedOption.value && add) {
        aux.push(oleicultor);
      }
    });
    setAvisoOleicultor(aux);
    myRef.current.onMenuOpen();
    setTimeout(function () {
      myRef.current.onMenuClose();
    }, 10);
  };

  const handleIsGeneral = () => {
    setAvisoIsGeneral(!aviso_isGeneral);
    setAvisoOleicultor([]);
    setAvisoEntidad([]);
  };

  const [open, setOpen] = React.useState(false);
  const [openEdit, setOpenEdit] = React.useState(false);
  const [openConfirm, setOpenConfirm] = React.useState(false);
  const handleClickOpen = () => {
    clearForm();
    setOpen(true);
  };
  const handleClickOpenEdit = () => {
    setOpenEdit(true);
  };
  const handleClickOpenConfirm = (id) => {
    setOpenConfirm(true);
    setAvisoId(id);
  };
  const handleClose = () => {
    setOpen(false);
    setOpenEdit(false);
    setOpenConfirm(false);
  };

  const styles = {
    cardCategoryWhite: {
      "&,& a,& a:hover,& a:focus": {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0",
      },
      "& a,& a:hover,& a:focus": {
        color: "#FFFFFF",
      },
    },
    cardTitleWhite: {
      color: "#FFFFFF",
      marginTop: "0px",
      minHeight: "auto",
      fontWeight: "300",
      fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
      marginBottom: "3px",
      textDecoration: "none",
      "& small": {
        color: "#777",
        fontSize: "65%",
        fontWeight: "400",
        lineHeight: "1",
      },
    },
    root: {
      width: "100%",
    },
  };
  const useStyles = makeStyles(styles);
  Moment.locale("es");

  useEffect(() => {
    getAvisos();
  }, []);

  async function getAvisos() {
    setIsLoad(false);
    await API.post("avisos/index", {
      findBy: toSeach,
    })
      .then((res) => {
        if (res.data.listaOleicultores) {
          setListOleicultorOptions(res.data.listaOleicultores);
        }
        if (res.data.listaEntidades) {
          setListEntidadesOptions(res.data.listaEntidades);
        }
        if (res.data.listaCategorias) {
          setListCategoriaOptions(res.data.listaCategorias);
        }
        if (res.data.listaZonas) {
          setListZonaOptions(res.data.listaZonas);
        }
        if (res.data.avisos.length > 0) {
          const arrayAvisos = [];
          res.data.avisos.forEach((aviso) => {
            var oleicultoresArray = [];
            if (aviso["oleicultores"]) {
              aviso["oleicultores"].forEach((oleicultor) => {
                oleicultoresArray.push({
                  value: oleicultor["id"],
                  label: oleicultor["nombre"] + " (" + oleicultor["dni"] + ")",
                });
              });
            }
            let categoria = aviso["categoria"];
            let aux = createData(
              aviso["nombre"],
              Moment(aviso["created_at"]).format("DD-MM-YYYY HH:mm:ss"),
              <div className={classes.root}>
                <Actions
                  edit={true}
                  onEdit={() =>
                    loadEdit(
                      aviso["id"],
                      aviso["nombre"],
                      aviso["descripcion"],
                      aviso["is_general"] == 1 ? true : false,
                      oleicultoresArray,
                      categoria
                        ? { value: categoria["id"], label: categoria["nombre"] }
                        : []
                    )
                  }
                  del={true}
                  onDelete={() => handleClickOpenConfirm(aviso["id"])}
                />
              </div>
            );

            arrayAvisos.push(aux);
          });
          setAvisos(arrayAvisos);
          setIsLoad(true);
        }
      })
      .catch((err) => {
        console.log(err);
        showSnack("warning", "Se ha producido un error en la carga de avisos");
      });
  }

  function formAvisos() {
    return (
      <>
        <GridItem xs={8} sm={8} md={8} style={{ marginTop: "10px" }}>
          <TextField
            label="Nombre"
            defaultValue={aviso_nombre}
            onChange={(event) => {
              const { value } = event.target;
              setAvisoNombre(value);
            }}
            error={error && aviso_nombre == "" ? true : false}
            fullWidth={true}
          />
        </GridItem>
        <GridItem
          xs={4}
          sm={4}
          md={4}
          style={{
            marginTop: "20px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <FormControlLabel
            control={
              <Checkbox
                checked={aviso_isGeneral}
                onChange={() => handleIsGeneral()}
                value="checkedB"
                color="primary"
                classes={{
                  root: classes.radioCustom,
                  checked: classes.checked,
                }}
              />
            }
            label="General"
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={12} style={{ marginTop: "10px" }}>
          <Editor
            editorState={aviso_descripcion}
            toolbarClassName="toolbarClassName"
            wrapperClassName="wrapperClassName"
            editorClassName="editorClassName"
            onEditorStateChange={(value) => {
              setAvisoDescripcion(value);
            }}
            localization={{
              locale: "es",
            }}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={12} style={{ marginTop: "20px" }}>
          <Select2
            value={aviso_categoria}
            onChange={handleAvisoCategoria}
            options={listCategoriaOptions}
            className={
              error && aviso_categoria.value == null ? classes.error : ""
            }
            placeholder="Añadir categoria"
            menuPosition={"fixed"}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={12} style={{ marginTop: "20px" }}>
          <Select2
            isMulti
            value={aviso_zona}
            onChange={handleAvisoZona}
            options={listZonaOptions}
            className={error && aviso_zona.value == null ? classes.error : ""}
            placeholder="Añadir zona"
            menuPosition={"fixed"}
          />
        </GridItem>
        {!aviso_isGeneral && (aviso_zona == null || aviso_zona.length == 0) && (
          <>
            <GridItem xs={12} sm={12} md={12} style={{ marginTop: "20px" }}>
              <Select2
                value={aviso_entidad}
                onChange={handleAvisoEntidad}
                options={listEntidadesOptions}
                className={
                  error && aviso_entidad.value == null ? classes.error : ""
                }
                placeholder="Añadir entidad"
                menuPosition={"fixed"}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={12} style={{ marginTop: "20px" }}>
              <Select2
                isMulti
                value={aviso_oleicultor}
                onChange={handleAvisoOleicultor}
                options={listOleicultorOptions}
                className={
                  error && aviso_oleicultor.value == null ? classes.error : ""
                }
                placeholder="Selecciona oleicultor"
                menuPosition={"fixed"}
                ref={myRef}
              />
            </GridItem>
          </>
        )}
      </>
    );
  }

  async function createAviso() {
    if (!isProcessing) {
      console.log(aviso_categoria);
      var validate_fields = new Map([
        ["nombre", aviso_nombre],
        ["descripcion", aviso_descripcion],
        ["categoria", aviso_categoria],
      ]);
      var validate = validateFields(validate_fields);
      setError(false);
      if (
        validate.status &&
        (aviso_isGeneral ||
          aviso_oleicultor.length > 0 ||
          aviso_zona.length > 0)
      ) {
        setIsProcessing(true);
        await API.post("avisos/store", {
          nombre: aviso_nombre,
          descripcion: draftToHtml(
            convertToRaw(aviso_descripcion.getCurrentContent())
          ),
          oleicultores: aviso_oleicultor,
          is_general: aviso_isGeneral,
          categoria_id: aviso_categoria["value"],
          zonas: aviso_zona,
        })
          .then((res) => {
            if (res.data) {
              handleClose();
              getAvisos();
              showSnack("success", "Aviso creada correctamente");
              enviarNotificaciones(res.data);
            }
            setIsProcessing(false);
          })
          .catch((err) => {
            if (err.response.data.error) {
              showSnack("warning", err.response.data.error);
            } else {
              showSnack("warning", "Se ha producido un error");
            }
            setIsProcessing(false);
          });
      } else {
        if (validate.message) {
          showSnack("warning", validate.message);
        } else {
          showSnack(
            "warning",
            "Debes seleccionar si es un aviso general o si va vinculada a unos oleicultores en concreto"
          );
        }
        setError(true);
      }
    }
  }

  function clearForm() {
    setAvisoId("");
    setAvisoNombre("");
    setAvisoDescripcion(EditorState.createEmpty());
    setAvisoIsGeneral(false);
    setAvisoOleicultor([]);
    setAvisoEntidad([]);
  }

  function loadEdit(
    id,
    nombre,
    descripcion,
    is_general,
    oleicultores,
    categoria
  ) {
    setAvisoId(id);
    setAvisoNombre(nombre);
    const contentBlock = htmlToDraft(descripcion);
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks
      );
      const editorState = EditorState.createWithContent(contentState);
      setAvisoDescripcion(editorState);
    }
    setAvisoIsGeneral(is_general);
    setAvisoOleicultor(oleicultores);
    setAvisoCategoria(categoria);
    handleClickOpenEdit();
  }

  async function editAviso() {
    if (!isProcessing) {
      var validate_fields = new Map([
        ["nombre", aviso_nombre],
        ["descripcion", aviso_descripcion],
        ["categoria", aviso_categoria],
      ]);
      var validate = validateFields(validate_fields);
      setError(false);
      if (validate.status) {
        setIsProcessing(true);
        await API.post(`avisos/update/${aviso_id}`, {
          nombre: aviso_nombre,
          descripcion: draftToHtml(
            convertToRaw(aviso_descripcion.getCurrentContent())
          ),
          oleicultores: aviso_oleicultor,
          is_general: aviso_isGeneral,
          categoria_id: aviso_categoria["value"],
          zonas: aviso_zona,
        })
          .then((res) => {
            if (res.data) {
              handleClose();
              getAvisos();
              showSnack("success", "Aviso editado correctamente");
            }
            setIsProcessing(false);
          })
          .catch((err) => {
            if (err.response.data.error) {
              showSnack("warning", err.response.data.error);
            } else {
              showSnack("warning", "Se ha producido un error");
            }
            setIsProcessing(false);
          });
      } else {
        showSnack("warning", validate.message);
        setError(true);
      }
    }
  }

  async function deleteAviso() {
    if (!isProcessing) {
      setIsProcessing(true);
      await API.post(`avisos/destroy/${aviso_id}`)
        .then((res) => {
          if (res.data) {
            handleClose();
            getAvisos();
            showSnack("success", "Aviso eliminado correctamente");
            setIsProcessing(false);
          }
        })
        .catch((err) => {
          console.log(err);
          setIsProcessing(false);
          showSnack("warning", "Se ha producido un error, eliminando la aviso");
        });
    }
  }

  const classes = useStyles();

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="info">
            <h4 className={classes.cardTitleWhite}>Avisos</h4>
            <p className={classes.cardCategoryWhite}>Gestión de avisos</p>
          </CardHeader>
          <CardBody>
            <GridContainer direction="row" alignItems="flex-end">
              <GridItem xs={3} sm={3} md={3}>
                <Fab
                  style={{ backgroundColor: PARAMS.firstColor, color: "#fff" }}
                  variant="extended"
                  onClick={() => handleClickOpen()}
                >
                  <AddIcon />
                  Crear aviso
                </Fab>
              </GridItem>
              <GridItem xs={3} sm={3} md={3}>
                <TextField
                  label="Buscar"
                  defaultValue={toSeach}
                  onChange={(event) => {
                    const { value } = event.target;
                    setToSearch(value);
                  }}
                  onKeyPress={(event) => {
                    if (event.key === "Enter") {
                      event.preventDefault();
                      const { value } = event.target;
                      setToSearch(value);
                      getAvisos();
                    }
                  }}
                />
                <Fab
                  style={{ backgroundColor: PARAMS.firstColor, color: "#fff" }}
                  variant="extended"
                  onClick={() => getAvisos()}
                >
                  <SearchIcon />
                </Fab>
              </GridItem>
            </GridContainer>
            <div className={classes.root}>
              {isLoad ? (
                <>
                  <Table
                    tablePaginate={true}
                    tableHead={tableHead}
                    tableData={avisos}
                  />
                </>
              ) : (
                <>
                  <GridContainer
                    style={{ width: "100%", height: "300px" }}
                    direction="row"
                    alignItems="center"
                    justify="center"
                  >
                    <Loader
                      type={PARAMS.loaderType}
                      color={PARAMS.firstColor}
                      timeout={5000}
                      height={100}
                      width={100}
                    />
                  </GridContainer>
                </>
              )}
            </div>
          </CardBody>
        </Card>
      </GridItem>
      <div>
        <Modal
          open={open}
          onCancel={() => handleClose()}
          content={formAvisos()}
          onConfirm={() => createAviso()}
          title="Crear aviso"
          width="lg"
        />
        <Modal
          open={openEdit}
          onCancel={() => handleClose()}
          content={formAvisos()}
          onConfirm={() => editAviso()}
          title="Editar aviso"
          width="lg"
        />
        <Modal
          open={openConfirm}
          onCancel={() => handleClose()}
          onConfirm={() => deleteAviso()}
          confirmText="Confirmar"
          confirmIcon={
            <CheckCircleOutlineIcon
              style={{ marginRight: "10px", color: "#fff" }}
            />
          }
          title="¿Seguro que deseas borrar el aviso?"
        />
      </div>
    </GridContainer>
  );
}
