import React, { useState, useEffect } from "react";

// components
import Table from "components/Table/TableUi";
import Modal from "components/Modal/Modal";
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import Button from "components/CustomButtons/Button";
import {instance as API} from "utils/API";
import PARAMS from "utils/PARAMS";
import { validateFields, showSnack } from 'utils/helperFunctions';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import Loader from 'react-loader-spinner';
import Actions from "components/Actions/Actions";
import Select2 from 'react-select';

// Core
import { makeStyles } from "@material-ui/core/styles";
import TextField from '@material-ui/core/TextField';
import Fab from '@material-ui/core/Fab';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

// Icons
import AddIcon from '@material-ui/icons/Add';
import EditIcon from "@material-ui/icons/Edit";
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import SearchIcon from '@material-ui/icons/Search';
import PublishIcon from '@material-ui/icons/Publish';

export default function Oleicultores() {
  const [oleicultores, setOleicultores] = useState([]);
  const [tableHead, setTableHead] = useState([
    {id: "nombre", numeric: false, disablePadding: true, label: 'Nombre'},
    {id: "dni", numeric: false, disablePadding: true, label: 'DNI'},
    {id: "entidad", numeric: false, disablePadding: true, label: 'Entidad'},
    {id: "activo", numeric: false, disablePadding: true, label: 'Activo'},
    {id: "acciones", numeric: false, disablePadding: true, label: 'Acciones'},
  ]);

  function createData(nombre, dni, entidad, activo, acciones) {
    return { nombre, dni, entidad, activo, acciones };
  }

  const [isLoad, setIsLoad] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [isProcessing, setIsProcessing] = React.useState(false);
  const [toSeach, setToSearch] = React.useState(null);
  const [oleicultor_id, setOleicultorId] = React.useState('');
  const [oleicultor_name, setOleicultorName] = React.useState('');
  const [oleicultor_dni, setOleicultorDni] = React.useState('');
  const [oleicultor_email, setOleicultorEmail] = React.useState('');
  const [oleicultor_telefono, setOleicultorTelefono] = React.useState('');
  const [oleicultor_pass, setOleicultorPass] = React.useState('');
  const [oleicultor_pass_confirm, setOleicultorPassConfirm] = React.useState('');
  const [oleicultor_activo, setOleicultorActivo] = React.useState(false);
  const [importing, setImporting] = React.useState(false);

  const [oleicultorEntidad, setOleicultorEntidad] = React.useState([]);
  const [listEntidadesOptions, setListEntidadesOptions] = React.useState([]);
  const handleOleicultorEntidad = selectedOption => {
    setOleicultorEntidad(selectedOption);
  };

  const [open, setOpen] = React.useState(false);
  const [openEdit, setOpenEdit] = React.useState(false);
  const [openConfirm, setOpenConfirm] = React.useState(false);
  const handleClickOpen = () => {
    clearForm();
    setOpen(true);
  };
  const handleClickOpenEdit = () => {
    setOpenEdit(true);
  };
  const handleClickOpenConfirm = (id) => {
    setOpenConfirm(true);
    setOleicultorId(id);
  };
  const handleClose = () => {
    setOpen(false);
    setOpenEdit(false);
    setOpenConfirm(false);
  };

  const handleCheckActivo= () => {
    setOleicultorActivo(!oleicultor_activo);
  };

  const styles = {
    cardCategoryWhite: {
      "&,& a,& a:hover,& a:focus": {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0"
      },
      "& a,& a:hover,& a:focus": {
        color: "#FFFFFF"
      }
    },
    cardTitleWhite: {
      color: "#FFFFFF",
      marginTop: "0px",
      minHeight: "auto",
      fontWeight: "300",
      fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
      marginBottom: "3px",
      textDecoration: "none",
      "& small": {
        color: "#777",
        fontSize: "65%",
        fontWeight: "400",
        lineHeight: "1"
      }
    },
    root: {
      width: '100%',
    },
  };
  const useStyles = makeStyles(styles);

  useEffect(() => {
    getOleicultores();

    const inputOleicultores = document.getElementById('inputFileOleicultores');

    inputOleicultores.addEventListener('change', () => {
      importOleicultores(inputOleicultores.files[0]);
      document.getElementById("inputFileOleicultores").value = "";
    })
  }, []);

  async function importOleicultores(input) {
    setImporting(true);
    let data = new FormData();
    data.append('file', input);
    let settings = { headers: { 'content-type': 'multipart/form-data' } };
    await API.post("oleicultores/import", data, settings)
    .then(res => {
      showSnack('success', 'Cargado correctamente');
      setImporting(false);
      setTimeout(function () {
        getOleicultores();
      }, 1000);
    })
    .catch(err => {
      setImporting(false);
      if (err.response.data.error) {
        showSnack('warning', err.response.data.error);
      }else{
        showSnack('warning', 'Se ha producido un error');
      }
    });
  }

  async function getOleicultores() {
    setIsLoad(false);
    await API.post("oleicultores/index", {
      findBy: toSeach
    })
    .then(res => {
      if (res.data.listaEntidades) {
        setListEntidadesOptions(res.data.listaEntidades);
      }
      if (res.data.oleicultores.length > 0) {
        const arrayOleicultores = [];
        res.data['oleicultores'].forEach(oleicultor => {
          let entidad = oleicultor['entidad'];
          let user = oleicultor['user'];
          let aux = createData(
            oleicultor["nombre"],
            oleicultor["dni"],
            ((entidad) ? entidad['nombre'] : ''),
            ((user && user['activo']) ? 'Si' : 'No'),
            (<div className={classes.root}>
              <Actions
                edit={true}
                onEdit={() => loadEdit(
                  oleicultor['id'],
                  oleicultor['nombre'],
                  oleicultor['dni'],
                  ((user && user['email']) ? user['email'] : ''),
                  oleicultor['telefono'],
                  ((entidad) ? {'value': entidad['id'], 'label': entidad['nombre']} : []),
                  ((user && user['activo']) ? user['activo'] : false)
                )}
                del={true}
                onDelete={() => handleClickOpenConfirm(oleicultor['id'])}
              />
            </div>)
          );

          arrayOleicultores.push(aux);
        });
        setOleicultores(arrayOleicultores);
        setIsLoad(true);
      }
    })
    .catch(err => {
      console.log(err);
      showSnack('warning', 'Se ha producido un error en la carga de oleicultores')
    });
  }

  function formOleicultores(){
    return(<>
      <GridItem xs={6} sm={6} md={6}>
        <TextField
          label="Nombre"
          defaultValue={oleicultor_name}
          onChange={event => {
            const { value } = event.target;
            setOleicultorName(value);
          }}
          error={(error && oleicultor_name == '') ? true : false}
        />
      </GridItem>
      <GridItem xs={6} sm={6} md={6}>
        <TextField
          label="DNI"
          defaultValue={oleicultor_dni}
          onChange={event => {
            const { value } = event.target;
            setOleicultorDni(value);
          }}
          error={(error && oleicultor_dni == '') ? true : false}
        />
      </GridItem>
      <GridItem xs={6} sm={6} md={6} style={{ marginTop: "10px" }}>
        <TextField
          label="Teléfono"
          inputProps={{ pattern: "[a-z]" }}
          defaultValue={oleicultor_telefono}
          onChange={event => {
            const { value } = event.target;
            setOleicultorTelefono(value);
          }}
          inputProps={{ maxLength: 9 }}
        />
      </GridItem>
      <GridItem xs={6} sm={6} md={6} style={{ marginTop: "10px" }}>
        <TextField
          label="Email"
          defaultValue={oleicultor_email}
          onChange={event => {
            const { value } = event.target;
            setOleicultorEmail(value);
          }}
          error={(error && oleicultor_email == '') ? true : false}
        />
      </GridItem>
      <GridItem xs={6} sm={6} md={6} style={{ marginTop: "10px" }}>
        <TextField
          label="Contraseña"
          type="password"
          defaultValue={oleicultor_pass}
          onChange={event => {
            const { value } = event.target;
            setOleicultorPass(value);
          }}
          error={(error && oleicultor_pass == '') ? true : false}
        />
      </GridItem>
      <GridItem xs={6} sm={6} md={6} style={{ marginTop: "10px" }}>
        <TextField
          label="Confirma Contraseña"
          type="password"
          defaultValue={oleicultor_pass_confirm}
          onChange={event => {
            const { value } = event.target;
            setOleicultorPassConfirm(value);
          }}
          error={(error && oleicultor_pass_confirm == '') ? true : false}
        />
      </GridItem>
      <GridItem xs={12} sm={12} md={12} style={{ marginTop: "20px" }} >
        <Select2
          value={oleicultorEntidad}
          onChange={handleOleicultorEntidad}
          options={listEntidadesOptions}
          className={(error && oleicultorEntidad.value == null) ? classes.error : ""}
          placeholder ="Selecciona su entidad"
          menuPosition={'fixed'}
        />
      </GridItem>
      <GridItem xs={6} sm={6} md={6} style={{ marginTop: "20px" }} >
        <FormControlLabel
          control={
            <Checkbox
              checked={oleicultor_activo}
              onChange={() => handleCheckActivo()}
              value="checkedB"
              color="primary"
              classes={{root: classes.radioCustom, checked: classes.checked}}
            />
          }
          label="Activo"
        />
      </GridItem>
    </>);
  }

  async function createOleicultor(){
    if (!isProcessing) {
      var validate_fields = new Map([['nombre', oleicultor_name], ['email', oleicultor_email], ['contraseña', oleicultor_pass], ['confirmar contraseña', oleicultor_pass_confirm]]);
      var validate = validateFields(validate_fields);
      setError(false);
      if (validate.status) {
        setIsProcessing(true);
        if (oleicultor_pass !== oleicultor_pass_confirm) {
          setIsProcessing(false);
          showSnack('warning', 'Las contraseñas deben ser iguales');
          return false;
        }
        await API.post("oleicultores/store", {
          nombre: oleicultor_name,
          dni: oleicultor_dni,
          email: oleicultor_email,
          password: oleicultor_pass,
          telefono: oleicultor_telefono,
          activo: oleicultor_activo,
          entidad_id: oleicultorEntidad['value']
        })
        .then(res => {
          if (res.data) {
            handleClose();
            getOleicultores();
            showSnack('success', 'Oleicultor creado correctamente');
          }
          setIsProcessing(false);
        })
        .catch(err => {
          if (err.response.data.error && typeof err.response.data.error === 'string') {
            showSnack('warning', err.response.data.error);
          }else{
            showSnack('warning', 'Se ha producido un error');
          }
          setIsProcessing(false);
        });
      }else{
        showSnack('warning', validate.message);
        setError(true);
      }
    }
  };

  function clearForm(){
    setOleicultorId('');
    setOleicultorName('');
    setOleicultorDni('');
    setOleicultorEmail('');
    setOleicultorTelefono('');
    setOleicultorPass('');
    setOleicultorPassConfirm('');
    setOleicultorEntidad([]);
    setOleicultorActivo(false);
  }

  function loadEdit(id, nombre, dni, email, telefono, entidad, activo){
    setOleicultorId(id);
    setOleicultorName(nombre);
    setOleicultorDni(dni);
    setOleicultorEmail(email);
    setOleicultorTelefono(telefono);
    setOleicultorActivo(activo);
    setOleicultorEntidad(entidad);
    handleClickOpenEdit();
  }

  async function editOleicultor(){
    if (!isProcessing) {
      var validate_fields = new Map([['nombre', oleicultor_name], ['email', oleicultor_email]]);
      var validate = validateFields(validate_fields);
      setError(false);
      if (validate.status) {
        setIsProcessing(true);
        if (oleicultor_pass !== '' && oleicultor_pass !== oleicultor_pass_confirm) {
          showSnack('warning', 'Las contraseñas deben ser iguales');
          setIsProcessing(false);
          return false;
        }
        await API.post(`oleicultores/update/${oleicultor_id}`, {
          nombre: oleicultor_name,
          dni: oleicultor_dni,
          email: oleicultor_email,
          password: oleicultor_pass,
          telefono: oleicultor_telefono,
          activo: oleicultor_activo,
          entidad_id: oleicultorEntidad['value']
        })
        .then(res => {
          if (res.data) {
            handleClose();
            getOleicultores();
            showSnack('success', 'Oleicultor editado correctamente');
          }
          setIsProcessing(false);
        })
        .catch(err => {
          if (err.response.data.error && typeof err.response.data.error === 'string') {
            showSnack('warning', err.response.data.error);
          }else{
            showSnack('warning', 'Se ha producido un error');
          }
          setIsProcessing(false);
        });
      }else{
        showSnack('warning', validate.message);
        setError(true);
      }
    }
  }

  async function deleteOleicultor(){
    if (!isProcessing) {
      setIsProcessing(true);
      await API.post(`oleicultor/destroy/${oleicultor_id}`)
      .then(res => {
        if (res.data) {
          handleClose();
          getOleicultores();
          showSnack('success', 'Oleicultor eliminado correctamente');
          setIsProcessing(false);
        }
      })
      .catch(err => {
        console.log(err);
        setIsProcessing(false);
        showSnack('warning', 'Se ha producido un error, eliminando al oleicultor')
      });
    }
  };

  const classes = useStyles();

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="info">
            <h4 className={classes.cardTitleWhite}>Oleicultores</h4>
            <p className={classes.cardCategoryWhite}>
              Gestión de oleicultores
            </p>
          </CardHeader>
          <CardBody>
            <GridContainer direction="row"  alignItems="flex-end">
              <GridItem xs={3} sm={3} md={3}>
                <Fab style={{backgroundColor: PARAMS.firstColor, color: '#fff'}} variant="extended" onClick={() => handleClickOpen()}>
                  <AddIcon />
                  Crear oleicultor
                </Fab>
              </GridItem>
              <GridItem xs={3} sm={3} md={3} >
                <TextField
                  label="Buscar"
                  defaultValue={toSeach}
                  onChange={event => {
                    const { value } = event.target;
                    setToSearch(value);
                  }}
                  onKeyPress={(event) => {
                    if (event.key === 'Enter') {
                      event.preventDefault();
                      const { value } = event.target;
                      setToSearch(value);
                      getOleicultores()
                    }
                  }}
                />
                <Fab style={{backgroundColor: PARAMS.firstColor, color: '#fff'}} variant="extended" onClick={() => getOleicultores()}>
                  <SearchIcon />
                </Fab>
              </GridItem>
              <GridItem xs={4} sm={4} md={4} direccion="row" style={{display: 'flex', justifyContent: 'flex-start'}}>
                <Button
                  variant="contained"
                  component="label"
                  style={{backgroundColor: PARAMS.firstColor, color: '#fff'}}
                  fullWidth={true}
                >
                  <PublishIcon style={{marginRight: 5}}/>
                  Importar Oleicultores
                  <input type="file" id="inputFileOleicultores" style={{ display: "none" }} />
                </Button>
              </GridItem>
              <GridItem xs={2} sm={2} md={2} direccion="row" style={{display: 'flex', justifyContent: 'center'}}>
                {importing && <Loader type={PARAMS.loaderType} color={PARAMS.firstColor} height={35} width={100} />}
              </GridItem>
            </GridContainer>
            <div className={classes.root}>
              {(isLoad ?
                <>
                  <Table
                    tablePaginate={true}
                    tableHead={tableHead}
                    tableData={oleicultores}
                  />
                </>
              :
                <><GridContainer style={{width: '100%', height: '300px'}} direction="row"  alignItems="center" justify="center">
                    <Loader type={PARAMS.loaderType} timeout={5000} color={PARAMS.firstColor} height={100} width={100} />
                  </GridContainer>
                </>
              )}
            </div>
          </CardBody>
        </Card>
      </GridItem>
      <div>
        <Modal
          open={open}
          onCancel={() => handleClose()}
          content={formOleicultores()}
          onConfirm={() => createOleicultor()}
          title="Crear oleicultor"
        />
        <Modal
          open={openEdit}
          onCancel={() => handleClose()}
          content={formOleicultores()}
          onConfirm={() => editOleicultor()}
          title="Editar oleicultor"
        />
        <Modal
          open={openConfirm}
          onCancel={() => handleClose()}
          onConfirm={() => deleteOleicultor()}
          confirmText="Confirmar"
          confirmIcon={<CheckCircleOutlineIcon style={{marginRight: '10px', color: '#fff'}} />} title="¿Seguro que deseas borrar al oleicultor?"  />
      </div>
    </GridContainer>
  );
}
