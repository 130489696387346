import React, { useState, useEffect, useRef } from "react";

// components
import Table from "components/Table/TableUi";
import Modal from "components/Modal/Modal";
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import {instance as API} from "utils/API";
import PARAMS from "utils/PARAMS";
import { validateFields, showSnack } from 'utils/helperFunctions';
import Moment from 'moment';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import Loader from 'react-loader-spinner';
import Actions from "components/Actions/Actions";
import Dropzone from "components/DropZone/DropZone";
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

// Core
import { makeStyles } from "@material-ui/core/styles";
import TextField from '@material-ui/core/TextField';
import Fab from '@material-ui/core/Fab';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

// Icons
import AddIcon from '@material-ui/icons/Add';
import EditIcon from "@material-ui/icons/Edit";
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import SearchIcon from '@material-ui/icons/Search';
import Select2 from 'react-select';

export default function Categorias() {
  const [categorias, setCategorias] = useState([]);
  const [tableHead, setTableHead] = useState([
    {id: "nombre", date: false, disablePadding: true, label: 'Nombre'},
    {id: "acciones", date: false, disablePadding: true, label: 'Acciones'},
  ]);

  function createData(nombre, acciones) {
    return { nombre, acciones };
  }

  const myRef = useRef(null);
  const [isLoad, setIsLoad] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [isProcessing, setIsProcessing] = React.useState(false);
  const [categoria_id, setCategoriaId] = React.useState('');
  const [categoria_nombre, setCategoriaNombre] = React.useState('');
  const [categoria_imagen, setCategoriaImagen] = React.useState([]);
  const [categoria_imagen_guardada, setCategoriaImagenGuardada] = React.useState([]);

  const [categoria_noticias, setCategoriaNoticias] = React.useState(0);
  const [categoria_avisos, setCategoriaAvisos] = React.useState(0);
  const [categoria_mapa, setCategoriaMapa] = React.useState(0);
  const [categoria_parcelas, setCategoriaParcelas] = React.useState(0);
  const [categoria_perfil, setCategoriaPerfil] = React.useState(0);

  const [open, setOpen] = React.useState(false);
  const [openEdit, setOpenEdit] = React.useState(false);
  const [openConfirm, setOpenConfirm] = React.useState(false);
  const handleClickOpen = () => {
    clearForm();
    setOpen(true);
  };
  const handleClickOpenEdit = () => {
    setOpenEdit(true);
  };
  const handleClickOpenConfirm = (id) => {
    setOpenConfirm(true);
    setCategoriaId(id);
  };
  const handleClose = () => {
    setOpen(false);
    setOpenEdit(false);
    setOpenConfirm(false);
  };

  const handleImage = (img) => {
    var arrayImagenes = [];
    img.forEach((item, i) => {
      var previewUrl = item['meta']['previewUrl'];
      var xhr = new XMLHttpRequest();
      xhr.open('GET', previewUrl, true);
      xhr.responseType = 'blob';
      xhr.onload = function(e) {
        if (this.status == 200) {
          var myBlob = this.response;
          var reader = new FileReader();
          reader.readAsDataURL(myBlob);
          reader.onloadend = function() {
            var base64data = reader.result;
            arrayImagenes[0] = base64data;
          }
        }
      };
      xhr.send();
    });

    setCategoriaImagen(arrayImagenes);
  };

  const styles = {
    cardCategoryWhite: {
      "&,& a,& a:hover,& a:focus": {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0"
      },
      "& a,& a:hover,& a:focus": {
        color: "#FFFFFF"
      }
    },
    cardTitleWhite: {
      color: "#FFFFFF",
      marginTop: "0px",
      minHeight: "auto",
      fontWeight: "300",
      fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
      marginBottom: "3px",
      textDecoration: "none",
      "& small": {
        color: "#777",
        fontSize: "65%",
        fontWeight: "400",
        lineHeight: "1"
      }
    },
    root: {
      width: '100%',
    },
  };
  const useStyles = makeStyles(styles);
  Moment.locale('es');

  useEffect(() => {
    getCategorias();
  }, []);

  async function getCategorias() {
    setIsLoad(false);
    await API.post("categorias/index")
    .then(res => {
      if (res.data.categoria.length > 0) {
        const arrayCategorias = [];
        res.data.categoria.forEach(categoria => {
          let aux = createData(
            categoria["nombre"],
            (<div className={classes.root}>
              <Actions
                edit={true}
                onEdit={() => loadEdit(
                  categoria['id'],
                  categoria["nombre"],
                  categoria["icon"],
                  categoria["show_noticias"],
                  categoria["show_avisos"],
                  categoria["show_mapa"],
                  categoria["show_parcelas"],
                  categoria["show_perfil"]
                )}
                del={true}
                onDelete={() => handleClickOpenConfirm(categoria['id'])}
              />
            </div>)
          );

          arrayCategorias.push(aux);
        });
        setCategorias(arrayCategorias);
        setIsLoad(true);
      }
    })
    .catch(err => {
      console.log(err);
      showSnack('warning', 'Se ha producido un error en la carga de categorias')
    });
  }

  function formCategorias(){
    return(<>
      <GridItem xs={12} sm={12} md={12} style={{ marginTop: "10px" }}>
        <TextField
          label="Nombre"
          defaultValue={categoria_nombre}
          onChange={event => {
            const { value } = event.target;
            setCategoriaNombre(value);
          }}
          error={(error && categoria_nombre == '') ? true : false}
          fullWidth={true}
        />
      </GridItem>

      {(!categoria_imagen_guardada || !categoria_imagen_guardada['url']) && <>
        <GridItem xs={1} sm={1} md={1} style={{ marginTop: "10px" }}/>
      </>}
      <GridItem xs={10} sm={10} md={10} style={{ marginTop: "20px", marginBottom: "20px" }}>
        <Dropzone onLoadImage={(val) => handleImage(val)} initialFiles={categoria_imagen} multiple={false} maxFiles={1} insideMessage="Arrastre o suelte la imagen aquí, o haga clic para seleccionarla"/>
      </GridItem>
      {(categoria_imagen_guardada && categoria_imagen_guardada['url']) ?
        <GridItem xs={2} sm={2} md={2} style={{ marginTop: "20px", display: 'flex', flexDirection: 'column' }} >
          <p style={{fontSize: 20, fontWeight: 'bold', color: '#2484FF'}}>Imagen Guardada</p>
          <img src={categoria_imagen_guardada['url']} width="100%"/>
        </GridItem>
      :
        <GridItem xs={1} sm={1} md={1} style={{ marginTop: "10px" }}/>
      }

      <GridItem xs={2} sm={2} md={2} style={{ marginTop: "20px", display: 'flex', justifyContent: 'center', alignItems: 'center'}} >
        <FormControlLabel
          control={
            <Checkbox
              checked={categoria_noticias}
              onChange={() => setCategoriaNoticias(!categoria_noticias)}
              value="checkedB"
              color="primary"
              classes={{root: classes.radioCustom, checked: classes.checked}}
            />
          }
          label="Ver Noticias"
        />
      </GridItem>
      <GridItem xs={2} sm={2} md={2} style={{ marginTop: "20px", display: 'flex', justifyContent: 'center', alignItems: 'center'}} >
        <FormControlLabel
          control={
            <Checkbox
              checked={categoria_avisos}
              onChange={() => setCategoriaAvisos(!categoria_avisos)}
              value="checkedB"
              color="primary"
              classes={{root: classes.radioCustom, checked: classes.checked}}
            />
          }
          label="Ver Avisos"
        />
      </GridItem>
      <GridItem xs={2} sm={2} md={2} style={{ marginTop: "20px", display: 'flex', justifyContent: 'center', alignItems: 'center'}} >
        <FormControlLabel
          control={
            <Checkbox
              checked={categoria_mapa}
              onChange={() => setCategoriaMapa(!categoria_mapa)}
              value="checkedB"
              color="primary"
              classes={{root: classes.radioCustom, checked: classes.checked}}
            />
          }
          label="Ver Mapa"
        />
      </GridItem>
      <GridItem xs={2} sm={2} md={2} style={{ marginTop: "20px", display: 'flex', justifyContent: 'center', alignItems: 'center'}} >
        <FormControlLabel
          control={
            <Checkbox
              checked={categoria_parcelas}
              onChange={() => setCategoriaParcelas(!categoria_parcelas)}
              value="checkedB"
              color="primary"
              classes={{root: classes.radioCustom, checked: classes.checked}}
            />
          }
          label="Ver Parcelas"
        />
      </GridItem>
      <GridItem xs={2} sm={2} md={2} style={{ marginTop: "20px", display: 'flex', justifyContent: 'center', alignItems: 'center'}} >
        <FormControlLabel
          control={
            <Checkbox
              checked={categoria_perfil}
              onChange={() => setCategoriaPerfil(!categoria_perfil)}
              value="checkedB"
              color="primary"
              classes={{root: classes.radioCustom, checked: classes.checked}}
            />
          }
          label="Ver Perfil"
        />
      </GridItem>
    </>);
  }

  async function createCategoria(){
    if (!isProcessing) {
      var validate_fields = new Map([['nombre', categoria_nombre]]);
      var validate = validateFields(validate_fields);
      setError(false);
      if (validate.status) {
        if (categoria_noticias || categoria_avisos || categoria_mapa || categoria_parcelas || categoria_perfil) {
          setIsProcessing(true);
          await API.post("categorias/store", {
            nombre: categoria_nombre,
            icon: categoria_imagen,
            show_noticias: categoria_noticias,
            show_avisos: categoria_avisos,
            show_mapa: categoria_mapa,
            show_parcelas: categoria_parcelas,
            show_perfil: categoria_perfil
          })
          .then(res => {
            if (res.data) {
              handleClose();
              getCategorias();
              showSnack('success', 'Categoria creada correctamente');
            }
            setIsProcessing(false);
          })
          .catch(err => {
            if (err && err.response && err.response.data && err.response.data.error) {
              showSnack('warning', err.response.data.error);
            }else{
              showSnack('warning', 'Se ha producido un error');
            }
            setIsProcessing(false);
          });
        }else{
          showSnack('warning', 'Debe marcar alguna vista');
        }
      }else{
        if (validate.message) {
          showSnack('warning', validate.message);
        }
        setError(true);
      }
    }
  }

  function clearForm(){
    setCategoriaId('');
    setCategoriaNombre('');
    setCategoriaImagenGuardada([]);
  }

  function loadEdit(id, nombre, imagen, show_noticias, show_avisos, show_mapa, show_parcelas, show_perfil){
    setCategoriaId(id);
    setCategoriaNombre(nombre);
    setCategoriaImagenGuardada(imagen);
    setCategoriaNoticias(show_noticias);
    setCategoriaAvisos(show_avisos);
    setCategoriaMapa(show_mapa);
    setCategoriaParcelas(show_parcelas);
    setCategoriaPerfil(show_perfil);
    handleClickOpenEdit();
  }

  async function editCategoria(){
    if (!isProcessing) {
      var validate_fields = new Map([['nombre', categoria_nombre]]);
      var validate = validateFields(validate_fields);
      setError(false);
      if (validate.status) {
        if (categoria_noticias || categoria_avisos || categoria_mapa || categoria_parcelas || categoria_perfil) {
          setIsProcessing(true);
          await API.post(`categorias/update/${categoria_id}`, {
            nombre: categoria_nombre,
            icon: categoria_imagen,
            show_noticias: categoria_noticias,
            show_avisos: categoria_avisos,
            show_mapa: categoria_mapa,
            show_parcelas: categoria_parcelas,
            show_perfil: categoria_perfil
          })
          .then(res => {
            if (res.data) {
              handleClose();
              getCategorias();
              showSnack('success', 'Categoria editada correctamente');
            }
            setIsProcessing(false);
          })
          .catch(err => {
            if (err && err.response && err.response.data && err.response.data.error) {
              showSnack('warning', err.response.data.error);
            }else{
              showSnack('warning', 'Se ha producido un error');
            }
            setIsProcessing(false);
          });
        }else{
          showSnack('warning', 'Debe marcar alguna vista');
        }
      }else{
        showSnack('warning', validate.message);
        setError(true);
      }
    }
  }

  async function deleteCategoria(){
    if (!isProcessing) {
      setIsProcessing(true);
      await API.post(`categorias/destroy/${categoria_id}`)
      .then(res => {
        if (res.data) {
          handleClose();
          getCategorias();
          showSnack('success', 'Categoria eliminado correctamente');
          setIsProcessing(false);
        }
      })
      .catch(err => {
        console.log(err);
        setIsProcessing(false);
        showSnack('warning', 'Se ha producido un error, eliminando la categoria')
      });
    }
  };

  const classes = useStyles();

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="info">
            <h4 className={classes.cardTitleWhite}>Categorías</h4>
            <p className={classes.cardCategoryWhite}>
              Gestión de categoría
            </p>
          </CardHeader>
          <CardBody>
            <GridContainer direction="row"  alignItems="flex-end">
              <GridItem xs={6} sm={6} md={6}>
                <Fab style={{backgroundColor: PARAMS.firstColor, color: '#fff'}} variant="extended" onClick={() => handleClickOpen()}>
                  <AddIcon />
                  Crear categoria
                </Fab>
              </GridItem>
            </GridContainer>
            <div className={classes.root}>
              {(isLoad ?
                <>
                  <Table
                    tablePaginate={true}
                    tableHead={tableHead}
                    tableData={categorias}
                  />
                </>
              :
                <><GridContainer style={{width: '100%', height: '300px'}} direction="row"  alignItems="center" justify="center">
                    <Loader type={PARAMS.loaderType} color={PARAMS.firstColor} timeout={5000} height={100} width={100} />
                  </GridContainer>
                </>
              )}
            </div>
          </CardBody>
        </Card>
      </GridItem>
      <div>
        <Modal
          open={open}
          onCancel={() => handleClose()}
          content={formCategorias()}
          onConfirm={() => createCategoria()}
          title="Crear categoria"
          width="lg"
        />
        <Modal
          open={openEdit}
          onCancel={() => handleClose()}
          content={formCategorias()}
          onConfirm={() => editCategoria()}
          title="Editar categoria"
          width="lg"
        />
        <Modal
          open={openConfirm}
          onCancel={() => handleClose()}
          onConfirm={() => deleteCategoria()}
          confirmText="Confirmar"
          confirmIcon={<CheckCircleOutlineIcon style={{marginRight: '10px', color: '#fff'}} />} title="¿Seguro que deseas borrar la categoria?"  />
      </div>
    </GridContainer>
  );
}
