import React, { Component } from "react";

import "bootstrap/dist/css/bootstrap.css";
import Login from "components/Login";
import PARAMS from "utils/PARAMS";
import GridContainer from "components/Grid/GridContainer";
import Loader from 'react-loader-spinner';
import { Router, Route, Switch, Redirect } from "react-router-dom";

// core components
import { createBrowserHistory } from "history";
import Admin from "layouts/Admin";
import NotFound from './NotFound';

const hist = createBrowserHistory();

class App extends Component {
  state = {
    apiToken: localStorage.getItem("apiToken") || "",
  };

  setApiToken = data => {
    this.setState({ apiToken: data.token });
  };

  render() {
    return (
      <div>
        {this.state.apiToken === "" && <Login setApiToken={this.setApiToken} />}
        {this.state.apiToken !== "" && (
          <>
            <div className="testing">
              <Router history={hist}>
                <Switch>
                  <Route path="/administrador" component={Admin} />
                  <Redirect from="/" to="/administrador/dashboard" />
                  <Route component={ NotFound }/>
                </Switch>
              </Router>
            </div>
          </>
        )}
      </div>
    );
  }
}

export default App;
