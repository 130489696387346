import React, { useState, useEffect } from "react";

// components
import Table from "components/Table/TableUi";
import Modal from "components/Modal/Modal";
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import {instance as API} from "utils/API";
import PARAMS from "utils/PARAMS";
import { validateFields, showSnack } from 'utils/helperFunctions';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import Loader from 'react-loader-spinner';
import Actions from "components/Actions/Actions";

// Core
import { makeStyles } from "@material-ui/core/styles";
import TextField from '@material-ui/core/TextField';
import Fab from '@material-ui/core/Fab';
import Button from "components/CustomButtons/Button";

// Icons
import AddIcon from '@material-ui/icons/Add';
import EditIcon from "@material-ui/icons/Edit";
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import SearchIcon from '@material-ui/icons/Search';
import PublishIcon from '@material-ui/icons/Publish';
import { SketchPicker   } from 'react-color';

export default function Alertas() {
  const [alertas, setAlertas] = useState([]);
  const [tableHead, setTableHead] = useState([
    {id: "tipo", numeric: false, disablePadding: true, label: 'Tipo'},
    {id: "color", numeric: false, disablePadding: true, label: 'Color'},
    {id: "acciones", numeric: false, disablePadding: true, label: 'Acciones'},
  ]);

  function createData(tipo, color, acciones) {
    return { tipo, color, acciones };
  }

  const [isLoad, setIsLoad] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [isProcessing, setIsProcessing] = React.useState(false);
  const [toSeach, setToSearch] = React.useState(null);
  const [alerta_id, setAlertaId] = React.useState('');
  const [alerta_tipo, setAlertaTipo] = React.useState('');
  const [alerta_color, setAlertaColor] = React.useState('');
  const [alerta_descripcion, setAlertaDescripcion] = React.useState('');

  const [open, setOpen] = React.useState(false);
  const [openEdit, setOpenEdit] = React.useState(false);
  const [openConfirm, setOpenConfirm] = React.useState(false);
  const handleClickOpen = () => {
    clearForm();
    setOpen(true);
  };
  const handleClickOpenEdit = () => {
    setOpenEdit(true);
  };
  const handleClickOpenConfirm = (id) => {
    setOpenConfirm(true);
    setAlertaId(id);
  };
  const handleClose = () => {
    setOpen(false);
    setOpenEdit(false);
    setOpenConfirm(false);
  };

  const styles = {
    cardCategoryWhite: {
      "&,& a,& a:hover,& a:focus": {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0"
      },
      "& a,& a:hover,& a:focus": {
        color: "#FFFFFF"
      }
    },
    cardTitleWhite: {
      color: "#FFFFFF",
      marginTop: "0px",
      minHeight: "auto",
      fontWeight: "300",
      fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
      marginBottom: "3px",
      textDecoration: "none",
      "& small": {
        color: "#777",
        fontSize: "65%",
        fontWeight: "400",
        lineHeight: "1"
      }
    },
    root: {
      width: '100%',
    },
  };
  const useStyles = makeStyles(styles);

  useEffect(() => {
    getAlertas();
  }, []);

  async function getAlertas() {
    setIsLoad(false);
    await API.post("alertas/index", {
      findBy: toSeach
    })
    .then(res => {
      if (res.data.length > 0) {
        const arrayAlertas = [];
        res.data.forEach(alerta => {
          let aux = createData(
            alerta["tipo"],
            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}><div style={{width: 50, height: 10, backgroundColor: alerta["color"]}}></div></div>,
            (<div className={classes.root}>
              <Actions
                edit={true}
                onEdit={() => loadEdit(
                  alerta['id'],
                  alerta['tipo'],
                  alerta['color'],
                  alerta['descripcion']
                )}
                del={true}
                onDelete={() => handleClickOpenConfirm(alerta['id'])}
              />
            </div>)
          );

          arrayAlertas.push(aux);
        });
        setAlertas(arrayAlertas);
        setIsLoad(true);
      }
    })
    .catch(err => {
      console.log(err);
      showSnack('warning', 'Se ha producido un error en la carga de alertas')
    });
  }

  function formAlertas(){
    return(<>
      <GridItem xs={6} sm={6} md={6}>
        <TextField
          label="Tipo"
          defaultValue={alerta_tipo}
          onChange={event => {
            const { value } = event.target;
            setAlertaTipo(value);
          }}
          error={(error && alerta_tipo == '') ? true : false}
        />
        <TextField
          multiline
          rows="10"
          label="Descripción"
          fullWidth={true}
          defaultValue={alerta_descripcion}
          onChange={event => {
            const { value } = event.target;
            setAlertaDescripcion(value);
          }}
          error={(error && alerta_descripcion == '') ? true : false}
          style={{marginTop: 30}}
        />
      </GridItem>
      <GridItem xs={6} sm={6} md={6} style={{marginTop: 30, marginBottom: 30}}>
        <SketchPicker  color={ alerta_color } onChangeComplete={(color, event) => {
          setAlertaColor(color.hex);
        }}/>
      </GridItem>
    </>);
  }

  async function createAlerta(){
    if (!isProcessing) {
      var validate_fields = new Map([['tipo', alerta_tipo], ['color', alerta_color], ['descripcion, alerta_descripcion']]);
      var validate = validateFields(validate_fields);
      setError(false);
      if (validate.status) {
        setIsProcessing(true);
        await API.post("alertas/store", {
          tipo: alerta_tipo,
          color: alerta_color,
          descripcion: alerta_descripcion
        })
        .then(res => {
          if (res.data) {
            handleClose();
            getAlertas();
            showSnack('success', 'Alerta creada correctamente');
          }
          setIsProcessing(false);
        })
        .catch(err => {
          if (err.response && err.response.data && err.response.data.error) {
            showSnack('warning', err.response.data.error);
          }else{
            showSnack('warning', 'Se ha producido un error');
          }
          setIsProcessing(false);
        });
      }else{
        showSnack('warning', validate.message);
        setError(true);
      }
    }
  };

  function clearForm(){
    setAlertaId('');
    setAlertaTipo('');
    setAlertaColor('');
    setAlertaDescripcion('');
  }

  function loadEdit(id, tipo, color, descripcion){
    setAlertaId(id);
    setAlertaTipo(tipo);
    setAlertaColor(color);
    setAlertaDescripcion(descripcion);
    handleClickOpenEdit();
  }

  async function editAlerta(){
    if (!isProcessing) {
      var validate_fields = new Map([['nombre', alerta_tipo], ['email', alerta_color], ['descripcion, alerta_descripcion']]);
      var validate = validateFields(validate_fields);
      setError(false);
      if (validate.status) {
        setIsProcessing(true);
        await API.post(`alertas/update/${alerta_id}`, {
          tipo: alerta_tipo,
          color: alerta_color,
          descripcion: alerta_descripcion
        })
        .then(res => {
          if (res.data) {
            handleClose();
            getAlertas();
            showSnack('success', 'Alerta editada correctamente');
          }
          setIsProcessing(false);
        })
        .catch(err => {
          if (err.response.data.error) {
            showSnack('warning', err.response.data.error);
          }else{
            showSnack('warning', 'Se ha producido un error');
          }
          setIsProcessing(false);
        });
      }else{
        showSnack('warning', validate.message);
        setError(true);
      }
    }
  }

  async function deleteAlerta(){
    if (!isProcessing) {
      setIsProcessing(true);
      await API.post(`alertas/destroy/${alerta_id}`)
      .then(res => {
        if (res.data) {
          handleClose();
          getAlertas();
          showSnack('success', 'Comercial eliminado correctamente');
          setIsProcessing(false);
        }
      })
      .catch(err => {
        console.log(err);
        setIsProcessing(false);
        showSnack('warning', 'Se ha producido un error, eliminando al comercial')
      });
    }
  };

  const classes = useStyles();

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="info">
            <h4 className={classes.cardTitleWhite}>Alertas</h4>
            <p className={classes.cardCategoryWhite}>
              Gestión de alertas
            </p>
          </CardHeader>
          <CardBody>
            <GridContainer direction="row"  alignItems="flex-end">
              <GridItem xs={3} sm={3} md={3}>
                <Fab style={{backgroundColor: PARAMS.firstColor, color: '#fff'}} variant="extended" onClick={() => handleClickOpen()}>
                  <AddIcon />
                  Crear alerta
                </Fab>
              </GridItem>
              <GridItem xs={3} sm={3} md={3} >
                <TextField
                  label="Buscar"
                  defaultValue={toSeach}
                  onChange={event => {
                    const { value } = event.target;
                    setToSearch(value);
                  }}
                  onKeyPress={(event) => {
                    if (event.key === 'Enter') {
                      event.preventDefault();
                      const { value } = event.target;
                      setToSearch(value);
                      getAlertas()
                    }
                  }}
                />
                <Fab style={{backgroundColor: PARAMS.firstColor, color: '#fff'}} variant="extended" onClick={() => getAlertas()}>
                  <SearchIcon />
                </Fab>
              </GridItem>
              <GridItem xs={3} sm={3} md={3}/>
              <GridItem xs={3} sm={3} md={3} direccion="row" style={{display: 'flex'}}></GridItem>
            </GridContainer>
            <div className={classes.root}>
              {(isLoad ?
                <>
                  <Table
                    tablePaginate={true}
                    tableHead={tableHead}
                    tableData={alertas}
                  />
                </>
              :
                <><GridContainer style={{width: '100%', height: '300px'}} direction="row"  alignItems="center" justify="center">
                    <Loader type={PARAMS.loaderType} color={PARAMS.firstColor} timeout={5000} height={100} width={100} />
                  </GridContainer>
                </>
              )}
            </div>
          </CardBody>
        </Card>
      </GridItem>
      <div>
        <Modal
          open={open}
          onCancel={() => handleClose()}
          content={formAlertas()}
          onConfirm={() => createAlerta()}
          title="Crear alerta"
        />
        <Modal
          open={openEdit}
          onCancel={() => handleClose()}
          content={formAlertas()}
          onConfirm={() => editAlerta()}
          title="Editar alerta"
        />
        <Modal
          open={openConfirm}
          onCancel={() => handleClose()}
          onConfirm={() => deleteAlerta()}
          confirmText="Confirmar"
          confirmIcon={<CheckCircleOutlineIcon style={{marginRight: '10px', color: '#fff'}} />} title="¿Seguro que deseas borrar la alerta?"  />
      </div>
    </GridContainer>
  );
}
