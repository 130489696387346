import PeopleIcon from '@material-ui/icons/People';
import ListAltIcon from '@material-ui/icons/ListAlt';
import ChromeReaderModeIcon from '@material-ui/icons/ChromeReaderMode';
import DescriptionIcon from '@material-ui/icons/Description';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import WarningIcon from '@material-ui/icons/Warning';
import RoomIcon from '@material-ui/icons/Room';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import EventNoteIcon from '@material-ui/icons/EventNote';
import ViewCarouselIcon from '@material-ui/icons/ViewCarousel';
import CategoryIcon from '@material-ui/icons/Category';

import Dashboard from "views/dashboard.js";
import Usuarios from "views/usuarios/usuarios";
import Noticias from "views/noticias/noticias";
import Categorias from "views/categorias/categorias";
import Avisos from "views/avisos/avisos";
import Archivos from "views/archivos/archivos";
import Oleicultores from "views/oleicultores/oleicultores";
import Parcelas from "views/parcelas/parcelas";
import Entidades from "views/entidades/entidades";
import Banners from "views/banners/banners";
import Alertas from "views/alertas/alertas";


const dashboardRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: PeopleIcon,
    component: Dashboard,
    layout: "/administrador",
    visible: false
  },
  {
    path: "/usuarios",
    name: "Usuarios",
    icon: PeopleIcon,
    component: Usuarios,
    layout: "/administrador",
    visible: true
  },
  {
    path: "/oleicultores",
    name: "Oleicultores",
    icon: AccountCircleIcon,
    component: Oleicultores,
    layout: "/administrador",
    visible: true
  },
  {
    path: "/entidades",
    name: "Entidades",
    icon: BusinessCenterIcon,
    component: Entidades,
    layout: "/administrador",
    visible: true
  },
  {
    path: "/parcelas",
    name: "Parcelas",
    icon: RoomIcon,
    component: Parcelas,
    layout: "/administrador",
    visible: true
  },
  {
    path: "/noticias",
    name: "Noticias",
    icon: ChromeReaderModeIcon,
    component: Noticias,
    layout: "/administrador",
    visible: true
  },
  {
    path: "/avisos",
    name: "Avisos",
    icon: EventNoteIcon,
    component: Avisos,
    layout: "/administrador",
    visible: true
  },
  {
    path: "/categorias",
    name: "Categorias",
    icon: CategoryIcon,
    component: Categorias,
    layout: "/administrador",
    visible: true
  },
  {
    path: "/banners",
    name: "Banners",
    icon: ViewCarouselIcon,
    component: Banners,
    layout: "/administrador",
    visible: true
  },
  {
    path: "/archivos",
    name: "Archivos",
    icon: DescriptionIcon,
    component: Archivos,
    layout: "/administrador",
    visible: true
  },
  {
    path: "/alertas",
    name: "Nivel de Alertas",
    icon: WarningIcon,
    component: Alertas,
    layout: "/administrador",
    visible: true
  }
];

export default dashboardRoutes;
