import React, { useState, useEffect } from "react";

// components
import Table from "components/Table/TableUi";
import Modal from "components/Modal/Modal";
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import {instance as API} from "utils/API";
import PARAMS from "utils/PARAMS";
import { validateFields, showSnack } from 'utils/helperFunctions';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import Loader from 'react-loader-spinner';
import Actions from "components/Actions/Actions";
import Dropzone from "components/DropZone/DropZone";
import {
  MuiPickersUtilsProvider,
  DatePicker,
} from '@material-ui/pickers';
import Moment from 'moment';
import "moment/locale/es";
import MomentUtils from '@date-io/moment';

// Core
import { makeStyles } from "@material-ui/core/styles";
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Fab from '@material-ui/core/Fab';
import Typography from '@material-ui/core/Typography';

// Icons
import SaveIcon from '@material-ui/icons/Save';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

Moment.locale('es');
const today = Moment(new Date()).add(1,'days').format('YYYY-MM-DD HH:mm:ss');

export default function Banner() {

  const [isLoad, setIsLoad] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [imagen_enlace, setImagenEnlace] = React.useState('');
  const [imagen_valido, setImagenValido] = React.useState(today);
  const [imagenes, setImagenes] = React.useState([]);
  const [imagenes_guardadas, setImagenesGuardadas] = React.useState([]);
  const [imagenes_borrar, setImagenesBorrar] = React.useState([]);
  const [imagenes_orden, setImagenesOrden] = React.useState([]);

  const handleDeleteImagen = (id, index) => {
    var toDelete = imagenes_borrar;
    toDelete.push(id)
    setImagenesBorrar(toDelete);

    var arrayG = imagenes_guardadas.slice();
    arrayG.splice(parseInt(index), 1);
    setImagenesGuardadas(arrayG);
    var arrayO = imagenes_orden.slice();
    arrayO.splice(parseInt(index), 1);
    setImagenesOrden(arrayO);
  };

  function move(arr, old_index, new_index) {

    if (new_index >= arr.length) {
      var k = new_index - arr.length + 1;
      while (k--) {
        arr.push(undefined);
      }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);

    setImagenesGuardadas(arr);
    var orden = [];
    arr.forEach(img => {
      orden.push(img['id']);
    });
    setImagenesOrden(orden);
  }

  const toLeftImagen = (index) => {
    const arr = imagenes_guardadas.slice();
    move(arr, parseInt(index), parseInt(index)-1);
  };
  const toRightImagen = (index) => {
    const arr = imagenes_guardadas.slice()
    move(arr, parseInt(index), parseInt(index)+1);
  };

  const handleImage = (img) => {
    var arrayImagenes = [];
    img.forEach((item, i) => {
      var previewUrl = item['meta']['previewUrl'];
      var xhr = new XMLHttpRequest();
      xhr.open('GET', previewUrl, true);
      xhr.responseType = 'blob';
      xhr.onload = function(e) {
        if (this.status == 200) {
          var myBlob = this.response;
          var reader = new FileReader();
          reader.readAsDataURL(myBlob);
          reader.onloadend = function() {
            var base64data = reader.result;
            arrayImagenes[0] = base64data;
          }
        }
      };
      xhr.send();
    });

    setImagenes(arrayImagenes);
  };

  const styles = {
    cardCategoryWhite: {
      "&,& a,& a:hover,& a:focus": {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0"
      },
      "& a,& a:hover,& a:focus": {
        color: "#FFFFFF"
      }
    },
    cardTitleWhite: {
      color: "#FFFFFF",
      marginTop: "0px",
      minHeight: "auto",
      fontWeight: "300",
      fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
      marginBottom: "3px",
      textDecoration: "none",
      "& small": {
        color: "#777",
        fontSize: "65%",
        fontWeight: "400",
        lineHeight: "1"
      }
    },
    root: {
      width: '100%',
    },
    radioCustom: {
      color: PARAMS.firstColor,
      '&$checked': {
        color: PARAMS.firstColor,
      },
    },
    checked: {}
  };
  const useStyles = makeStyles(styles);

  useEffect(() => {
    getImagenes();
  }, []);

  async function getImagenes() {
    setIsLoad(false);
    await API.post("banner/index")
    .then(res => {
      if (res.data) {
        var orden = [];
        res.data.forEach(img => {
          orden.push(img['id']);
        });
        setImagenesOrden(orden);
        setImagenesGuardadas(res.data);
        setIsLoad(true);
      }
    })
    .catch(err => {
      console.log(err);
      showSnack('warning', 'Se ha producido un error en la carga de imagenes')
    });
  }

  async function guardarImagenes() {
    setError(false);

    if ((imagenes && imagen_enlace && imagen_valido) || imagenes.length == 0) {
      await API.post("banner/store",{
        imagenes: imagenes,
        imagenes_borrar: imagenes_borrar,
        imagenes_orden: imagenes_orden,
        enlace: imagen_enlace,
        validez: Moment(imagen_valido).format('YYYY-MM-DD 00:00:00')
      })
      .then(res => {
        if (res.data) {
          getImagenes();
          setImagenes([]);
          setImagenesBorrar([]);
          setImagenEnlace('');
          setImagenValido(today);
          showSnack('success', 'Guardado correctamente')
        }
      })
      .catch(err => {
        console.log(err);
        showSnack('warning', 'Se ha producido un error en la carga de productos')
      });
    }else{
      setError(true);
      if (!imagen_enlace) {
        showSnack('warning', 'Debes intoducir un enlace para el banner')
      }
      if (!imagen_valido) {
        showSnack('warning', 'Debes intoducir la validez para el banner')
      }
    }
  }

  const classes = useStyles();

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="info">
            <h4 className={classes.cardTitleWhite}>Banner</h4>
            <p className={classes.cardCategoryWhite}>
              Gestión de imagenes
            </p>
          </CardHeader>
          <CardBody>
            <div className={classes.root}>
              {(isLoad ?
                <>
                  <GridItem xs={12} sm={12} md={12} style={{ marginTop: "10px" }}>
                    <Typography gutterBottom variant="subtitle2" style={{fontWeight: '700', fontSize: '1rem'}}>
                      Imágenes Actuales del Banner
                    </Typography>
                  </GridItem>
                  {imagenes_guardadas.length > 0 ?
                    <GridContainer xs={12} sm={12} md={12} style={{ margin: "10px", display: 'flex' }}>
                      {Object.keys(imagenes_guardadas).map((val, j) => {
                        return(<>
                          <GridItem xs={3} sm={3} md={3} style={{ margin: "30px 0px", display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <GridContainer direction="row" >
                              <GridItem xs={6} sm={6} md={6}>
                                {val != 0 && <Fab size="small" style={{backgroundColor: PARAMS.firstColor, color: '#fff', fontSize: '12px', textTransform:'capitalize'}} variant="extended" onClick={() => toLeftImagen(val)}>
                                  <ArrowBackIcon fontSize="small" />
                                </Fab>}
                              </GridItem>
                              <GridItem xs={6} sm={6} md={6}>
                                {(val != imagenes_guardadas.length -1) && <Fab size="small" style={{backgroundColor: PARAMS.firstColor, color: '#fff', fontSize: '12px', textTransform:'capitalize'}} variant="extended" onClick={() => toRightImagen(val)}>
                                  <ArrowForwardIcon fontSize="small" />
                                </Fab>}
                              </GridItem>
                            </GridContainer>
                            <a href={imagenes_guardadas[val]['enlace']} target="_blank">
                              <img title={`${imagenes_guardadas[val]['clicks']} clicks`} style={{width: '150px', height: '100px', padding: '10px'}} src={imagenes_guardadas[val]['url']} />
                            </a>
                            <Fab size="small" style={{backgroundColor: PARAMS.firstColor, color: '#fff', fontSize: '12px', textTransform:'capitalize'}} variant="extended" onClick={() => handleDeleteImagen(imagenes_guardadas[val]['id'], val)}>
                              <HighlightOffIcon fontSize="small" />
                              Eliminar
                            </Fab>
                          </GridItem>
                        </>)
                      })}
                    </GridContainer>
                  :
                    <GridItem xs={12} sm={12} md={12} style={{ margin: "10px 0px", display: 'flex' }}>
                      No hay imagenes
                    </GridItem>
                  }
                  <GridItem xs={12} sm={12} md={12} style={{ marginTop: "10px" }}>
                    <Typography gutterBottom variant="subtitle2" style={{fontWeight: '700', fontSize: '1rem'}}>
                      Añadir Imagen al Banner
                    </Typography>
                    <Dropzone onLoadImage={(val) => handleImage(val)} initialFiles={imagenes} multiple={false} maxFiles={1} insideMessage="Arrastre o suelte la imagen aquí, o haga clic para seleccionarla"/>
                    <GridContainer>
                      <GridItem xs={6} sm={6} md={3} style={{marginTop: 10}}>
                        <TextField
                          label="Enlace"
                          defaultValue={imagen_enlace}
                          onChange={event => {
                            const { value } = event.target;
                            setImagenEnlace(value);
                          }}
                          error={(error && imagen_enlace == '') ? true : false}
                        />
                      </GridItem>
                      <GridItem xs={6} sm={6} md={3}>
                        <MuiPickersUtilsProvider libInstance={Moment} utils={MomentUtils} locale='es'>
                          <GridContainer>
                            <DatePicker
                              autoOk
                              disableToolbar
                              variant="inline"
                              format="DD-MM-YYYY"
                              minDate={Moment(new Date()).add(1,'days')}
                              margin="normal"
                              id="date-picker-desde"
                              label="Válido"
                              value={imagen_valido}
                              onChange={(value) => {
                                setImagenValido(value);
                              }}
                              className={(error && imagen_valido == null) ? classes.error : ""}
                              style={{ marginLeft: "15px" }}
                            />
                          </GridContainer>
                        </MuiPickersUtilsProvider>
                      </GridItem>
                    </GridContainer>
                  </GridItem>
                  <GridContainer direction="row"  alignItems="flex-end" justify="flex-end" >
                    <GridItem xs={12} sm={12} md={12} style={{paddingTop: 20}}>
                      <Fab style={{backgroundColor: PARAMS.firstColor, color: '#fff'}} variant="extended" onClick={() => guardarImagenes()}>
                        <SaveIcon style={{marginRight: 15}} />
                        Confirmar cambios
                      </Fab>
                    </GridItem>
                  </GridContainer>
                </>
              :
                <><GridContainer style={{width: '100%', height: '300px'}} direction="row"  alignItems="center" justify="center">
                    <Loader type={PARAMS.loaderType} color={PARAMS.firstColor} timeout={5000} height={100} width={100} />
                  </GridContainer>
                </>
              )}
            </div>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}
