import React, {useEffect, useState} from 'react';
import Dropzone from 'react-dropzone-uploader'
import 'react-dropzone-uploader/dist/styles.css'
import PARAMS from "utils/PARAMS";


export default function DropZone(props) {
  const {onLoadImage, multiple, maxFiles, insideMessage} = props;

  const handleChangeStatus = ({ meta }, status, files) => {
    if (status == 'done') {
      onLoadImage(files);
    }
  }

  const [imagenes, setImagenes] = React.useState([]);

  return(
    <Dropzone
      multiple={multiple}
      onChangeStatus={handleChangeStatus}
      maxFiles={maxFiles}
      submitButtonDisabled={files => files.length < maxFiles}
      styles={{ dropzone: { minHeight: 200, maxHeight: 250 } }}
      submitButtonDisabled={true}
      inputWithFilesContent="Añadir imágenes"
      inputContent={insideMessage}
    />
  )
}
