import React from "react";
import { render } from 'react-dom'
import CustomSnack from "components/Snackbar/LoadSnack";


export function validateFields(fields){
  var BreakException = {};
  var error = [];
  error['status'] = true;
  var field = '';

  try {
    fields.forEach(function(el, i){
      if ((el === '' || el === null || el.length === 0) && error) {
        error['status'] = false;
        field = i;
        throw BreakException;
      }
    });
  } catch (e) {
    if (e !== BreakException) throw e;
  }

  if (!error.status) {
    error['message'] = 'El campo '+field+' no puede estar vacio';
  }

  return error;
}

export function showSnack(type, message){
  render(
    <CustomSnack
      type={type}
      message={message}
    />,
    document.querySelector('#aux'));
}
